import React from "react"
import TutorialLayout from "../../components/TutorialLayout"
import IMG from "../../images/P3Quest.svg"
import STEP_THREE_CONTENT from "../../images/LocDeConsum.svg"

const Second = () => (
  <TutorialLayout
    imageSrc={IMG}
    step="3"
    title="Adaugă un loc de consum"
    buttonLink="/salut/finalizer"
  >
    <img src={STEP_THREE_CONTENT} alt="App Store" style={{ width: "100%" }} />
    <ol style={{ paddingLeft: 20, marginTop: 16 }}>
      <li style={{ marginBottom: 6 }}>
        Accesează butonul <b>Locuri de consum </b>
        din aplicație și adaugă un nou loc de consum.
      </li>
      <li style={{ marginBottom: 6 }}>
        Folosește numărul personal și codul unic primite de la Primărie sau de
        la Asociația de proprietari. În cazul în care nu le-ați primit, vă rugăm
        sa solicitați aceste date. 
      </li>
      <span style={{ marginTop: 6 }}>
        <b>
          Atenție!  Nu confundați aceste date cu numărul de serie înscris pe
          card
        </b>
      </span>
      <li style={{ marginBottom: 6, marginTop: 6 }}>
        După confirmarea locului de consum poți vedea toate informațiile
        accesând butonul <b>Mai multe detalii.</b>
      </li>
    </ol>
  </TutorialLayout>
)

export default Second
